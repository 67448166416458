import React, { useRef, useState } from "react";
import "./contact.css";
import emailjs from "@emailjs/browser";
export default function Contact() {
  const form = useRef();
  const [showmessage, setshowmessage] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_363z6m7",
        "template_470z4q5",
        form.current,
        "tNA0yGrIxh18lNAen"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    document.getElementById("form").reset();
    setshowmessage(true);
    setTimeout(() => {
      setshowmessage(false);
    }, 5000);
  };

  return (
    <>
      <span className="anchor" id="Contact"></span>
      <div className="contact">
        <div className="contact_box">
          <h2 data-aos="fade-right" data-aos-duration="1000">
            Contact Form
          </h2>
          <form
            ref={form}
            onSubmit={sendEmail}
            action
            className="contact-form"
            id="form"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="contact_one">
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="John Doe"
                required
              />
            </div>

            <div className="contact_one">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="test@test.com"
                required
              />
            </div>
            <div className="contact_one">
              <label htmlFor="numeric">Phone number (optional)</label>
              <input
                type="tel"
                name="numeric"
                id="numeric"
                placeholder="+91 12345 12345"
              />
            </div>
            <div className="contact_one">
              <label htmlFor="Subject">Subject (optional)</label>
              <input
                type="text"
                name="Subject"
                id="Subject"
                placeholder="type here"
              />
            </div>
            <div className="contact_one text_area">
              <label htmlFor="Message">Message*</label>
              <textarea
                type="text"
                name="Message"
                id="Message"
                placeholder="type here"
                rows="4"
                required
              />
            </div>
            {showmessage && (
              <h2 className="success">Message send successfully</h2>
            )}

            <input type="submit" value="Send" className="btn" />

            {/* <input type="submit"  value="Send">
              Submit
            </input> */}
          </form>
        </div>
      </div>
    </>
  );
}
