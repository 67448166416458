import React, { useEffect, useState } from "react";
import "../index.css";
import twitter from "../img/icons/twitter.svg";
import linkedin from "../img/linkedin.svg";
import dribble from "../img/icons/dribbble.svg";
import { ReactComponent as Mode_1 } from "../img/mode-1.svg";
import { ReactComponent as Mode_2 } from "../img/mode-2.svg";
const mountedStyle = { animation: "inAnimation 0.5s ease-in" };
const unmountedStyle = {
  animation: "outAnimation 0.5s ease-out",
  animationFillMode: "forwards",
};

export default function Navbar() {
  const [menu, setmenu] = useState(false);

  const [mode, setmode] = useState(true);

  if (mode) {
    document.documentElement.className = "light";
  } else {
    document.documentElement.className = "dark";
  }

  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  console.log(menu);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    if (windowDimensions && windowDimensions?.width >= 750) {
      setmenu(true);
    }

    if (windowDimensions && windowDimensions?.width <= 750) {
      setmenu(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return (
    <>
      <nav className="nav_bar">
        <div className="nav_box">
          <h3>Ajith.</h3>

          {!menu && (
            <svg
              fill="#000000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="100px"
              height="100px"
              id="menu"
              onClick={(e) => setmenu((pre) => !pre)}
            >
              <path
                fill="none"
                stroke="#000000"
                stroke-miterlimit="10"
                stroke-width="2"
                d="M50 25L0 25M50 10L0 10M0 40L50 40"
              />
            </svg>
          )}

          {menu && (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100px"
              height="100px"
              viewBox="0 0 122.878 122.88"
              enable-background="new 0 0 122.878 122.88"
              onClick={(e) => setmenu((pre) => !pre)}
              id="menu_svg"
            >
              <g>
                <path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" />
              </g>
            </svg>
          )}

          {menu && (
            <ul className="links" style={menu ? mountedStyle : unmountedStyle}>
              <li>
                <a href="#service">Skills</a>
              </li>
              <li>
                <a href="#Portfolio">Side Projects</a>
              </li>
              <li>
                <a href="#About">About</a>
              </li>
              <li>
                <a href="#Contact">Contact</a>
              </li>
            </ul>
          )}
          {mode && (
            <Mode_1
              id="mode-1"
              cursor="pointer"
              onClick={(e) => setmode((pre) => !pre)}
            ></Mode_1>
          )}
          {!mode && (
            <Mode_2
              id="mode-2"
              cursor="pointer"
              onClick={(e) => setmode((pre) => !pre)}
            ></Mode_2>
          )}
          {menu && (
            <ul className="icons">
              <li>
                <img
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/in/ajithkumarak/",
                      "_blank"
                    )
                  }
                  src={linkedin}
                  alt="linkedin_logo"
                  className="svg"
                />
              </li>
              <li>
                <img src={twitter} alt="twitter_logo" className="svg" />
              </li>
              <li>
                <img src={dribble} alt="dribble_logo" className="svg" />
              </li>
            </ul>
          )}
        </div>
      </nav>
    </>
  );
}
