import React from "react";
import "./footer.css";
export default function Footer() {
  return (
    <div className="footer">
      <div className="footer_box">
        <p> Ajith.</p>
        <ul>
          <li>
            <a href="#"> Allright reserved © {new Date().getFullYear()}</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
