import React from "react";
import "./works.css";
import img1 from "../../img/portfolio-1.png";
import img2 from "../../img/portfolio-2.jpg";
import img3 from "../../img/portfolio-3.png";
import img4 from "../../img/portfolio-4.png";
export default function Works() {
  return (
    <>
      <span className="anchor" id="Portfolio"></span>
      <div className="works">
        <h2 data-aos="fade-right" data-aos-duration="1000">
          Some of my recent works
        </h2>
        <div className="contain">
          <div className="box" data-aos="fade-up" data-aos-duration="1000">
            <h3>ChatBot</h3>
            <h4>React.js, Google Gemini API, Express.js</h4>
            <div className="imageOverlay">
              <img src={img2} alt="" />

              <a
                href="https://openai-chatbot-client.vercel.app/"
                target="_blank"
                rel="noreferrer"
              >
                Visit Project 🔗
              </a>
            </div>
          </div>

          <div className="box" data-aos="fade-up" data-aos-duration="1000">
            <h3>Todo App</h3>
            <h4>Next.js, MongoDB</h4>
            <div className="imageOverlay">
              <img src={img1} alt="" />

              <a
                href="https://nextjs-todo-app-build.vercel.app/todo"
                target="_blank"
                rel="noreferrer"
              >
                Visit Project 🔗
              </a>
            </div>
          </div>
          <div className="box" data-aos="fade-up" data-aos-duration="1000">
            <h3>Crypo Price tracker</h3>
            <h4>Vite,SWR, React.js, Coincap API</h4>
            <div className="imageOverlay">
              <img src={img3} alt="" />

              <a
                href="https://crypto-ochre.vercel.app/"
                target="_blank"
                rel="noreferrer"
              >
                Visit Project 🔗
              </a>
            </div>
          </div>
          <div className="box" data-aos="fade-up" data-aos-duration="1000">
            <h3>Figma Design</h3>
            <h4>UI/UX Design, Prototyping</h4>
            <div className="imageOverlay">
              <img src={img4} alt="" />

              <a
                href="https://www.figma.com/file/PvMIZzkLWWgwY42KMeBWYs/1?node-id=0%3A1&t=VgXoBOE4x7CrMa9x-0"
                target="_blank"
                rel="noreferrer"
              >
                Visit Design 🔗
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
