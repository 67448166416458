import React from "react";
import "./Header.css";
import web from "../img/developer/19362653.png";
// import AOS from "aos";
// import "aos/dist/aos.css"; // You can also use <link> for styles
// // ..
// AOS.init();
export default function Header() {
  return (
    <section>
      <header className="header">
        <div className="box">
          <div className="left" data-aos="fade-right" data-aos-duration="1000">
            <h1>Hello, I’m AjithKumar</h1>
            <h2> Web Developer | React JS | Next JS | Vite</h2>
            <p>
              I'm a seasoned React developer with 4 years of experience,
              specializing in building modern web applications using React,
              Next.js, Vite, and Webpack. I focus on creating efficient,
              scalable, and user-friendly solutions.
            </p>
            <div
              className="btn-container"
              onClick={() => {
                document
                  .querySelector("#Contact")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              <button className="btn">
                <a href="#Contact">Connect with me</a>
              </button>
            </div>
          </div>

          <div className="right">
            <img src={web} alt="developer_Illustration" className="web_dev" />
          </div>
        </div>
      </header>
    </section>
  );
}
