import React from "react";
import "./client.css";
export default function Client() {
  return (
    <div className="client">
      <h1 data-aos="fade-right" data-aos-duration="1000">
        Lorem ipsum
      </h1>
      <h3 data-aos="fade-right" data-aos-duration="1000">
        Lorem ipsum dolor sit amet
      </h3>

      <div className="contain">
        <div className="box" data-aos="slide-up" data-aos-duration="1000">
          <div className="whitebox">
            <div>
              <h2>Quote Testimonials</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Molestiae maiores.
              </p>
            </div>
          </div>
          <div className="name">
            <h3>John Doe</h3>
            <h4>CEO at ABC corporation</h4>
          </div>
        </div>

        <div className="box" data-aos="slide-up" data-aos-duration="1000">
          <div className="whitebox">
            <div>
              <h2>Quote Testimonials</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Molestiae maiores.
              </p>
            </div>
          </div>
          <div className="name">
            <h3>John Doe</h3>
            <h4>CEO at ABC corporation</h4>
          </div>
        </div>

        <div className="box" data-aos="slide-up" data-aos-duration="1000">
          <div className="whitebox">
            <div>
              <h2>Quote Testimonials</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Molestiae maiores.
              </p>
            </div>
          </div>
          <div className="name">
            <h3>John Doe</h3>
            <h4>CEO at ABC corporation</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
