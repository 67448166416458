import React from "react";
import "./service.css";
import icon1 from "../../img/icons/icon-1.png";
import icon2 from "../../img/icons/icon-2.png";
import icon3 from "../../img/icons/icon-3.png";
import icon4 from "../../img/icons/icon-4.png";
import icon5 from "../../img/icons/icon-5.png";
import icon6 from "../../img/icons/icon-6.png";

export default function Service() {
  return (
    <>
      <span className="anchor" id="service"></span>
      <div className="service">
        <h2 data-aos="fade-right" data-aos-duration="1000">
          Technical skills &<br></br>development expertise
        </h2>
        <div className="contain">
          <div className="box-1" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon1} alt="icon1" />
            <h3>React Development</h3>
            <p>
              4 years of experience building modern web applications using
              React, Redux, and hooks. Strong focus on clean code and reusable
              components.
            </p>
          </div>

          <div className="box-2" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon2} alt="icon2" />
            <h3>Next.js Development</h3>
            <p>
              Creating fast, SEO-friendly applications with Next.js,
              implementing server-side rendering and static site generation.
            </p>
          </div>

          <div className="box-3" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon3} alt="icon3" />
            <h3>Testing Implementation</h3>
            <p>
              Writing comprehensive test suites using Jest, Vitest, and React
              Testing Library to ensure code reliability and quality.
            </p>
          </div>

          <div className="box-4" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon4} alt="icon4" />
            <h3>Accessible Development</h3>
            <p>
              Building web applications with accessibility in mind, following
              WCAG guidelines to ensure inclusive user experiences.
            </p>
          </div>

          <div className="box-5" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon5} alt="icon4" />
            <h3>Frontend Tooling</h3>
            <p>
              Experience with modern build tools like Vite, implementing
              performance optimizations and development workflows.
            </p>
          </div>

          <div className="box-6" data-aos="fade-up" data-aos-duration="1000">
            <img src={icon6} alt="icon6" />
            <h3>UI Development</h3>
            <p>
              Creating responsive and interactive user interfaces with modern
              CSS, Material UI, and component libraries.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
