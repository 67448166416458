import React from "react";
import "./about.css";
import about from "../../img/about.png";
export default function About() {
  return (
    <>
      <span className="anchor" id="About"></span>
      <div className="about">
        <div className="about_container">
          <div className="about_right">
            <img src={about} alt="" />
          </div>
          <div className="about_left">
            <h2 data-aos="fade-right" data-aos-duration="1000">
              About me
            </h2>
            <p data-aos="fade-up" data-aos-duration="1000">
              I'm Ajith, a passionate Frontend Developer with 4 years of
              experience specializing in React ecosystem. I love building
              accessible, performant web applications and sharing knowledge with
              the developer community.
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              My expertise includes modern React development with Next.js and
              Vite, implementing robust testing strategies using Jest, Vitest,
              and React Testing Library. I'm deeply committed to web
              accessibility (A11y) and creating inclusive user experiences that
              work for everyone.
            </p>
            <p data-aos="fade-up" data-aos-duration="1000">
              I'm open to exciting opportunities where I can leverage my
              experience in building scalable frontend solutions. Whether it's
              about a potential collaboration, technical consultation, or just a
              chat about web development, I'd love to connect!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
