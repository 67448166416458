import React from "react";
import { ReactComponent as React_logo } from "../../img/skills/react.svg";
import { ReactComponent as Js_logo } from "../../img/skills/js.svg";
import { ReactComponent as Css_logo } from "../../img/skills/css.svg";
import { ReactComponent as Node_logo } from "../../img/skills/nodejs.svg";
import { ReactComponent as Mongo_logo } from "../../img/skills/mongodb.svg";
import { ReactComponent as vite } from "../../img/skills/vite.svg";
import { ReactComponent as jest } from "../../img/skills/jest.svg";
import { ReactComponent as vitest } from "../../img/skills/vitest.svg";
import { ReactComponent as nextjs } from "../../img/skills/nextjs.svg";
import "./Skills.css";

export default function Skills() {
  const skillLogos = [
    { Logo: React_logo, name: "React" },
    { Logo: Js_logo, name: "JavaScript" },
    { Logo: Css_logo, name: "CSS" },
    { Logo: Node_logo, name: "Node.js" },
    { Logo: Mongo_logo, name: "MongoDB" },
    { Logo: vite, name: "Vite" },
    { Logo: jest, name: "Jest" },
    { Logo: vitest, name: "vitest" },
    { Logo: nextjs, name: "Next.js" },
  ];

  return (
    <section>
      <div
        className="border"
        data-aos="slide-right"
        data-aos-duration="1000"
      ></div>
      <div
        className="icon_box"
        data-aos="zoom-in"
        data-aos-duration="1500"
        data-aos-delay="300"
      >
        {skillLogos.map(({ Logo, name }) => (
          <Logo key={name} className="skill_icons" title={name} />
        ))}
      </div>
      <div
        className="border"
        data-aos="slide-left"
        data-aos-duration="1000"
      ></div>
    </section>
  );
}
